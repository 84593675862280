jQuery(
    (function ($) {
        "use strict";

        // Header Sticky
        $(window).on("scroll", function () {
            if ($(this).scrollTop() > 120) {
                $(".navbar").addClass("is-sticky");
            } else {
                $(".navbar").removeClass("is-sticky");
            }
        });

        $(document).on("click", ".navbar-collapse.in", function (e) {
            if (
                $(e.target).is("a") &&
                $(e.target).attr("class") != "dropdown-toggle"
            ) {
                $(this).collapse("hide");
            }
        });

        $(".navbar .navbar-nav li a").on("click", function () {
            $(".navbar-collapse").collapse("hide");
        });

        $(".home-banner-slider").owlCarousel({
            loop: true,
            nav: false,
            dots: true,
            autoplayHoverPause: true,
            autoplay: true,
            smartSpeed: 1000,
            margin: 30,
            navText: [
                "<i class='flaticon-curve-arrow'></i>",
                "<i class='flaticon-curve-arrow-1'></i>",
            ],
            items: 1,
        });

        // Screenshot Slider
        $(".screenshot-slider").owlCarousel({
            loop: true,
            nav: false,
            dots: true,
            autoplayHoverPause: true,
            autoplay: true,
            smartSpeed: 1000,
            margin: 15,
            navText: [
                "<i class='flaticon-curve-arrow'></i>",
                "<i class='flaticon-curve-arrow-1'></i>",
            ],
            responsive: {
                0: {
                    items: 1,
                },
                320: {
                    items: 1.2,
                },
                370: {
                    items: 1.5,
                },
                576: {
                    items: 2,
                },
                768: {
                    items: 2.5,
                },
                1024: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
                1300: {
                    items: 4,
                },
                1600: {
                    items: 5,
                },
            },
        });

        // Screenshot Slider
        $(".partner-slider").owlCarousel({
            loop: true,
            nav: true,
            dots: false,
            autoplayHoverPause: true,
            autoplay: true,
            smartSpeed: 1000,
            margin: 10,
            navText: [
                "<i class='fa fa-angle-left'></i>",
                "<i class='fa fa-angle-right'></i>",
            ],
            centerMode: true,
            responsive: {
                0: {
                    items: 1,
                },
                320: {
                    items: 1,
                },
                400: {
                    items: 2,
                },
                768: {
                    items: 4,
                },
                1024: {
                    items: 4,
                },
                1200: {
                    items: 5,
                },
                1300: {
                    items: 6,
                },
            },
        });

        // Feedback Carousel
        $(".feedback-carousel").owlCarousel({
            loop: true,
            nav: true,
            dots: true,
            autoplayHoverPause: true,
            autoplay: true,
            smartSpeed: 1000,
            margin: 30,
            navText: [
                "<i class='flaticon-curve-arrow'></i>",
                "<i class='flaticon-curve-arrow-1'></i>",
            ],
            items: 1,
        });

        // Tabs
        (function ($) {
            $(".tab ul.tabs")
                .addClass("active")
                .find("> li:eq(0)")
                .addClass("current");
            $(".tab ul.tabs li a").on("click", function (g) {
                var tab = $(this).closest(".tab"),
                    index = $(this).closest("li").index();
                tab.find("ul.tabs > li").removeClass("current");
                $(this).closest("li").addClass("current");
                tab.find(".tab_content")
                    .find("div.tabs_item")
                    .not("div.tabs_item:eq(" + index + ")")
                    .slideUp();
                tab.find(".tab_content")
                    .find("div.tabs_item:eq(" + index + ")")
                    .slideDown();
                g.preventDefault();
            });
        })(jQuery);

        // Popup Video
        $(".popup-youtube").magnificPopup({
            disableOn: 320,
            type: "iframe",
            mainClass: "mfp-fade",
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
        });

        // Go to Top
        $(function () {
            // Scroll Event
            $(window).on("scroll", function () {
                var scrolled = $(window).scrollTop();
                if (scrolled > 600) $(".go-top").addClass("active");
                if (scrolled < 600) $(".go-top").removeClass("active");
            });
            // Click Event
            $(".go-top").on("click", function () {
                $("html, body").animate({ scrollTop: "0" }, 500);
            });
        });
    })(jQuery)
);
